<template>
  <b-modal
    static
    ref="my-modal"
    id="modal-1"
    :title="modalTitle"
    no-close-on-esc
    :class="!is_data_load ? 'opacity-custom' : 'opacity-1' "
    no-close-on-backdrop
    hide-footer
    hide-header-close
    header-bg-variant="warning"
    size="lg">
     <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="CalendarIcon" />
          <span>{{ $t("Planning") }}</span>
        </template>
        <b-card-text>
           <b-form-group
                :label="$t('Trainer Name')"
                label-for="trainer_name">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="trainer_name"
                    name="trainer_name"
                    type="text"
                    readonly
                    background="white"
                    :placeholder="$t('Facility Name')"
                    v-model="appointment.trainer_name"/>
                  </b-input-group>
                  <small v-if="isSubmit && !$v.appointment.is_trainer_id"
              class="form-text text-danger">{{ $t("Trainer Selection Required") }}</small>
          </b-form-group>
          
          <b-form-group
                :label="$t('Activity')"
                label-for="activity_name">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="activity_name"
                    name="activity_name"
                    type="text"
                    readonly
                    background="white"
                    :placeholder="$t('Facility Name')"
                    @blur="$v.appointment.activity_name.$touch()"
                    v-model="appointment.activity_name"/>                  
                  </b-input-group>
                <small v-if="isSubmit && !$v.appointment.is_activity_id"
                    class="form-text text-danger">{{ $t("Activity Selection Required") }}</small>
          </b-form-group>
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="start_time"></label>{{ $t("Start Time") }}
                <v-select
                  v-model="appointment.start_time"
                  :options="startTimeScala"
                  :reduce="item => item.key" label="label"
                  class="select-config">
                </v-select>
                 <small  v-if="isSubmit && !$v.appointment.start_time.required"
                      class="form-text text-danger">{{ $t("Start time required") }}</small>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <label for="end_time"></label>{{ $t("End Time") }}
                <v-select
                    v-model="appointment.end_time"
                    :options="endTimeScala"
                    :reduce="item => item.key" label="label"
                    class="select-config">
                </v-select>
                 <small  v-if="isSubmit && !$v.appointment.end_time.required"
                      class="form-text text-danger">{{ $t("End time required") }}</small>
              </b-form-group>
            </b-col>
          </b-row>


           <b-row>
            <b-col>
              <b-form-group
                ><label for="title">{{ $t("Title") }}</label>
                <b-form-input
                  id="title"
                  v-model="appointment.title"
                  label="title"
                  @blur="$v.appointment.title.$touch()"/>
                <small
                  v-if="isSubmit && !$v.appointment.title.required"
                  class="form-text text-danger"
                  >{{ $t("This field is required") }}</small
                >
              </b-form-group>

              <b-form-group
                ><label for="definition">{{ $t("Description") }}</label>
                <b-form-textarea
                  id="defination"
                  v-model="appointment.definition"
                  placeholder=""
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group v-if="false">
                <b-row>
                  <b-col sm="12">
                    <label for="delay-minute">{{
                      $t("Cannot Join After How Many Minutes")
                    }}</label>
                    <v-select
                      v-model="minute"
                      :options="delayMinute"
                      class="select-config">
                    </v-select>
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-group>
                <b-row>
                  <b-col sm="6">
                    <b-form-group>
                      <label for="start_date"></label>{{ $t("Start Date") }}
                      <b-form-input
                        id="start_date"
                        type="date"
                        v-model="appointment.start_date"
                           disabled
                        label="start_date"/>
                        <small
                      v-if="isSubmit && !$v.appointment.start_date.required"
                      class="form-text text-danger">{{ $t("Start date required") }}</small>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group>
                      <label for="end_date"></label>{{ $t("End Date") }}
                      <b-form-input
                        id="end_date"
                        type="date"
                        v-model="appointment.end_date"
                        disabled
                        label="end_date"/>
                        <small v-if="isSubmit && !$v.appointment.end_date.required"
                      class="form-text text-danger">{{ $t("End date required") }}</small>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>

              <template v-if="group_count > 1"> <!-- Grup id den birden fazla is çoklu kayıt yapılmıştır  -->
                   <b-form-checkbox
                    class="input-zoom"
                    id="checkbox-1"
                    v-model="is_group"
                    name="checkbox-1">
                    <p>{{ $t("Influence co-created appointments") }}</p>
                  </b-form-checkbox>
                </template>
            </b-col>          
            </b-row>         
        </b-card-text>
      </b-tab>
      <b-tab>
      <template #title>
          <feather-icon icon="UsersIcon" />
          <span>{{ $t("Participants") }}</span>
      </template>
      <b-row>
          <b-col>
        <div>
          <span class="text-success">  AKTİVİTEYE KATILANLAR </span>
        </div>
        <div>
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1 radius-0"
                size="sm"
                :placeholder="$t('Search...')"/>
          </div>
        <div>
            <b-table
              hover
              :current-page="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aling="right"
              first-number
              last-number
              small="small"
              ref="refUserListTable"
              class="text-left position-relative"
              :items="ActivityParticipants"
              responsive="sm"
              sticky-header="300px"
              :fields="ActivityParticipantColumns"
              :filter-included-fields="filterOn"
              :filter="filter"
              @filtered="onFiltered"
              primary-key="id"
              show-empty
              :empty-text="$t('No Matching Creates Found')"
              :empty-filtered-text="$t('There are no records matching your request!')">
              <template #cell(actions)="data">
                  <b-button size="" style="padding:2px;" class="m-0" variant="outline-danger" @click="removeCustomer(data.item,data.index)" >
                          <feather-icon  icon="XIcon" size="24"/></b-button>
              </template>    
            </b-table>
                          <div class="mx-2">
                <b-row>
                  <div>
                    <small v-if="$i18n.locale === 'tr'" class="mt-1">
                      {{ totalRows }} kayıttan
                      {{ currentPage * perPage - perPage }}-{{
                        currentPage * perPage
                      }}
                      arası gösteriliyor
                    </small>
                  </div>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                    sm=""
                    class="d-flex align-items-right justify-content-right justify-content-sm-end radius0"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      aling="right"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0 r-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
        </div>
          </b-col>
          <b-col>
        <div>
              <span class="text-danger">AKTİVİTEYE KATILMAYANLAR</span>  
        </div>
         <div>
              <b-form-input
                v-model="filter2"
                class="d-inline-block mr-1"
                size="sm"
                :placeholder="$t('Search...')"
              />
        </div>
        <div>
            <b-table
              hover
              :current-page="currentPage2"
              :total-rows="totalRows2"
              :per-page="perPage2"
              aling="right"
              first-number
              last-number
              small="small"
              ref="refUserListTable"
              class="text-left position-relative"
              :items="InActivityParticipants"
              responsive="sm"
              sticky-header="300px"
              :fields="ActivityParticipantColumns"
              :filter-included-fields="filterOn2"
              :filter="filter2"
              @filtered="onFiltered2"
              primary-key="id"
              show-empty
              :empty-text="$t('No Matching Creates Found')"
              :empty-filtered-text="$t('There are no records matching your request!')">
            <template  #cell(actions)="data">
                      <b-button size="" style="padding:2px;" class="m-0" variant="outline-success"  @click="addCustomer(data.item,data.index)" >
                        <feather-icon  icon="CheckIcon" size="24"/></b-button>
            </template>
            
            </b-table> 
              <div class="mx-2">
                <b-row>
                  <div>
                    <small v-if="$i18n.locale === 'tr'" class="mt-1">
                      {{ totalRows2 }} kayıttan
                      {{ currentPage2 * perPage2 - perPage2 }}-{{
                        currentPage2 * perPage2
                      }}
                      arası kayıt gösteriliyor
                    </small>
                  </div>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                    sm=""
                    class="d-flex align-items-right justify-content-right justify-content-sm-end radius0"
                  >
                    <b-pagination
                      v-model="currentPage2"
                      :total-rows="totalRows2"
                      :per-page="perPage2"
                      aling="right"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0 r-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
             </div>
          </b-col>
        </b-row>

      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="GridIcon" />
          <span>{{ $t("Rooms") }}</span>
        </template>
        <b-form-group>
          <label for="title">{{ $t("Room") }}</label>
          <v-select
            :reduce="x => x.room_id" 
            v-model="room.room_id"
            value="room_id"
            :options="optionsRoom"
            label="room_name"
            class="select-config">
            <span slot="no-options">{{ $t("No Suitable Room Found") }}</span>
          </v-select>
        </b-form-group>
        <b-form-group>
          <label for="title">{{ $t("Capacity") }}</label>
          <b-form-input
            v-model="appointment.capacity"
            id="basicInput"
            :placeholder="$t('Capacity')"
          />
        </b-form-group>
      </b-tab>
      <hr />
      <template>
        <div class="row mt-1">
          <div class="col">
            <b-button variant="danger"
             :disabled=!is_data_load
            @click="deleteAppointment">
            {{ $t("Delete") }}
            </b-button>
          </div>
          <div class="float-right mr-1">
            <b-button variant="warning" class="mr-1" @click="cancel" :disabled=!is_data_load>
              {{ $t("Cancel") }}
            </b-button>
            <b-button variant="primary" @click="appointmentUpdate()"  :disabled=!is_data_load>
              
              {{ $t("Update") }}
            </b-button>
          </div>
        </div>
      </template>
        <b-button 
          variant="outline-default"
          :disabled="true" 
         :hidden=is_data_load
          style="position:absolute;display:block;top:40%;left:30%;"    
          class="mr-1">
          <b-spinner 
           style="width: 2rem; height: 2rem; "
           class="mr-1"/>

             <!-- <small v-if="isSubmit && !$v.appointment.is_trainer_id"
              class="form-text text-danger">{{ $t("Trainer Selection Required") }}</small> -->


            <h3 style="color:#FF9F43;">
                <span style="textColor:7B70F1;" v-if="spinMessage==1">  {{ $t("Loading please wait") }} </span>
                <span style="textColor:7B70F1;" v-if="spinMessage==2">  {{ $t("Saving please wait") }} </span>
                <span style="textColor:7B70F1;" v-if="spinMessage==3">  {{ $t("Updating please wait") }} </span>
                <span style="textColor:7B70F1;" v-if="spinMessage==4">  {{ $t("Deleting please wait") }} </span>
                <span style="textColor:7B70F1;" v-if="spinMessage==5">  {{ $t("Loading participants please wait") }} </span>
            </h3> 
            <!-- <h3 style="color:#FF9F43;"><span style="textColor:7B70F1;">{{ $t("Loading please wait...") }} </span></h3>   -->
        </b-button>
    </b-tabs>
  </b-modal>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import moment from 'moment'
//import Cleave from 'vue-cleave-component'
import { ref } from "@vue/composition-api";
//import models from "@/models";
// import Participants from "../Participants.vue";
import {

  BFormInput,
  BAvatar,
  BMediaAside,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckboxGroup,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BCardText,
  BCard,
  BCardBody,
  BCollapse,
  VBToggle,
  BTabs,
  BTab,
  BPopover,
  VBPopover,
  BFormTextarea,
  BBordered,
  BTable,
  BPagination,
} from "bootstrap-vue";
//import { mapActions, mapGetters, mapState } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { endTimeScalaModel, startTimeScalaModel } from '@/models/timeScala'

export default {
  name: "EditModalAppointment",
  components: {
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BCard,
    BCardBody,
    BCardText,
    vSelect,
    BMediaAside,
    BAvatar,
    BCollapse,
    VBToggle,
    BTabs,
    BTab,
    BPopover,
    VBPopover,
    BFormTextarea,
    BTable,
    BBordered,
    BInputGroup,  
    BInputGroupAppend,
    BPagination,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-popover": VBPopover,
    Ripple,
  },
  data() {
    return {
      modalTitle: "",
   //   isAddNewLicenceSidebarActive: false,
      show: false,
      disabled: false,
      block_time: "not-checked",

       room:
       {
        room_id: "00000000-0000-0000-0000-000000000000",
        room_name: this.$t("Select"),
        is_active: true,
        capacity:5
       },
      optionsRoom: [],
      selectedDays: [],
      ActivityParticipants:[],//Aktiviteye katılmış olan katılımcılar
      InActivityParticipants:[],//Aktiviteye katılmamış olan katılımcılar
      ActivityParticipantColumns:[
      {
        key: 'time_table_detail_id',
        label: this.$t('time_table_detail_id'),
        thClass: 'd-none',
        tdClass: 'd-none',
      },
      {
        key: 'time_table_id',
        label: this.$t('time_table_id'),
        thClass: 'd-none',
        tdClass: 'd-none'
      },
      {
        key: 'person_id',
        label: this.$t('person_id'),
        thClass: 'd-none',
        tdClass: 'd-none'
      },
      {
        key: 'customer_product_sales_id',
        label: this.$t('time_table_detail_id'),
        thClass: 'd-none',
        tdClass: 'd-none'
      },
      {
        key: 'trainer_product_id',
        label: this.$t('time_table_detail_id'),
        thClass: 'd-none',
        tdClass: 'd-none'
      },
      {
        key: 'customer_name',
        label: this.$t('FULL NAME'),
        sortable: true,
      },
      {
        key: 'actions',
        label:"",
        tdClass: 'text-center',
      },],
      appointment: {
          company_branch_id:"",
          title: "",
          definition: "",
          start_date: "",
          end_date: "",
          start_time: "",
          end_time: "",
          activity_id: null, //trainer calendar edit, handle event click ile gelecek düzenlenecek aktivite, aktivite idsi
          room_id: null, //trainer calendar edit, handle event click ile gelecek düzenlecek aktivite, salon idsi
          person_id: null,
          trainer_id: "00000000-0000-0000-0000-000000000000",
          trainer_name:null,
          trainer_product_id: null,
          activity_name:null,
          capacity: null,
          activity_type_id: "00000000-0000-0000-0000-000000000000",
          time_table_id: "00000000-0000-0000-0000-000000000000",
          group_id:"00000000-0000-0000-0000-000000000000" // aynı anda birden fazla eklenen kayıt için         
      },

      is_group: false, //Değişiklik yapılan randevular birlikte etkilenecek ise true başlangıçta false olması gerekiyor.
      group_count:0,
      is_data_load:false,
      currentPage: 1,
      totalRows: 1,
      sortDesc: false,
      sortBy: "id",
      filter: null,
      perPageOptions: [10, 25, 50, 100],
      perPage: 25,
      filterOn: [],

      currentPage2: 1,
      totalRows2: 1,
      isSortDirDesc2: false,
      sortDesc2: false,
      sortBy2: "id",
      filter2: null,
      perPageOptions2: [10, 25, 50, 100],
      perPage2: 25,
      filterOn2: [],
      // timeScala: [],
      isPT: false,
      isSubmit: false,   
      delayMinute: ["0", "10", "15", "20", "25", "30"],
      minute: "0",
      selectedActivityTime: null,
      startTimeScala: startTimeScalaModel,
      endTimeScala: endTimeScalaModel,

      spinMessage:5// 1 Yükleniyor //2 Kaydediliyor 3 Güncelleniyor 4 Siliniyor   Spin içinde kulanılacak olan mesajlar. 

    };
  },
  setup() {
    const appointment = ref();
    return {
      appointment,
    };
  },
  methods: {
    sendToParentData(val)
      {
        this.$emit("editAppointmentData",val);
      },
 
    cancel() {
      
       this.hideModal();
    },

    
    //Katılımcı ekle 
    addCustomer(val,index)
    {
        this.InActivityParticipants.splice(index,1);
        this.ActivityParticipants.push(val)
    },

    //Katılımcı kaldır
    removeCustomer(val,index)
    {
      this.ActivityParticipants.splice(index,1)
      this.InActivityParticipants.push(val)
    },

      //katılımcı listesi arama aksiyonu
    onFiltered2(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems.length;
      this.currentPage2 = 1;
    },
    //katılımcı listesi arama aksiyonu
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    appointmentSheduleEdit(company_branch_id,time_table_id, trainer_id) 
    {
      this.spinMessage=1 //yükleniyor lütfen bekleyiniz
        this.$database.AppointmentService.appointment_shedule_edit(company_branch_id,time_table_id,trainer_id
      ).then((res) => {
         if (res.IsSuccess === true || res.IsSuccess === 1) {
           this.appointment.company_branch_id=company_branch_id
           this.appointment.title =res.Result.TimeTable.SubjectTitle 
           this.appointment.trainer_name =res.Result.Trainer.FirstName 
           this.appointment.activity_name =res.Result.Activity.Name
           this.appointment.activity_id =res.Result.Activity.ActivityId
           this.appointment.definition =res.Result.TimeTable.Description
          //this.appointment.start_date =res.Result.TimeTable.StartDate
          //this.appointment.end_date =res.Result.TimeTable.EndDate
           //this.appointment.start_time =res.Result.TimeTable.StartTime
           //this.appointment.end_time =res.Result.TimeTable.EndTime
           this.appointment.room_id =res.Result.TimeTable.RoomId
           this.appointment.person_id =res.Result.TimeTable.PersonId
          // this.appointment.trainer_id =res.Result.Trainer.TrainerId
           this.appointment.trainer_product_id =res.Result.TimeTable.TrainerProductId
           this.appointment.capacity =res.Result.TimeTable.Capacity
           this.appointment.group_id =res.Result.TimeTable.GroupId
           this.appointment.activity_type_id ="00000000-0000-0000-0000-000000000000"// Activite tipi artık olmadığından 
           this.group_count=res.Result.GroupCount

          if(res.Result.ActivityParticipants != null)
          {
            res.Result.ActivityParticipants.map((el) => {       
                let ActivityParticipant={};
                  ActivityParticipant.time_table_detail_id =  el.TimeTableDetailId
                  ActivityParticipant.time_table_id =  time_table_id
                  ActivityParticipant.person_id =  el.PersonId 
                  ActivityParticipant.capacity =  el.Capacity 
                  ActivityParticipant.room_id =  el.RoomId
                  ActivityParticipant.customer_name =  el.CustomerName
                  ActivityParticipant.customer_product_sales_id =  el.CustomerProductSalesId
                  ActivityParticipant.trainer_product_id =  el.TrainerProductId
                this.ActivityParticipants.push(ActivityParticipant)
            });
          }
          if(res.Result.InActivityParticipants != null)
          {
            res.Result.InActivityParticipants.map((ell) => {       
                let InActivityParticipant={};
                  InActivityParticipant.time_table_detail_id =  ell.TimeTableDetailId
                  InActivityParticipant.time_table_id = time_table_id // Appointment te gelen time tableId ell.TimeTableId
                  InActivityParticipant.person_id =  ell.PersonId 
                  InActivityParticipant.customer_name =  ell.CustomerName
                  InActivityParticipant.customer_product_sales_id =  ell.CustomerProductSalesId
                  InActivityParticipant.trainer_product_id =  ell.TrainerProductId
                this.InActivityParticipants.push(InActivityParticipant)
            });
          }

        if(res.Result.Rooms != null)
          {
             this.optionsRoom=[];
            res.Result.Rooms.map((ell) => {       
                let room={};
                  room.room_id =  ell.RoomId
                  room.company_branch_id =  ell.CompanyBranchId
                  room.room_name =  ell.RoomName 
                  room.capacity= ell.Capacity
                this.optionsRoom.push(room)
            });
          }

            // console.log(
            //               {"is_group":this.is_group },
            //               {"TimeTableRequest":this.appointment},
            //               {"ActivityParticipants": this.ActivityParticipants}, 
            //               {"InActivityParticipants": this.InActivityParticipants}
            //               )
         }
              
            this.is_data_load=true;  // Yükleme tamamlandıktan sonra animasyonu kapat
            this.room.room_id=res.Result.TimeTable.RoomId
            
       });
    },

    showModal()
     {
      this.room= {
        room_id: "00000000-0000-0000-0000-000000000000",
        room_name: this.$t("Select"),
        capacity:5,
        is_active: true,
      };

      this.appointment.title="";
      this.appointment.definition="";
      this.is_group= false;
      this.filter=null;
      this.filter2=null;
      this.is_data_load=false,     
      this.$refs["my-modal"].show();
       this.appointmentSheduleEdit(this.$store.getters["auth/userInfo"].company_branch_id,this.appointment.time_table_id,this.appointment.trainer_id)
    this.$store.state.app.loading = false // Gene spin i kapat

      this.modalTitle = this.$t("Edit Calendar Field") + ' (' + moment(this.appointment.start_date).format('DD.MM.YYYY') + ')';

    },
    hideModal() {

      this.ActivityParticipants=[],//Aktiviteye katılmış olan katılımcılar
      this.InActivityParticipants=[],//Aktiviteye katılmamış olan katılımcıla
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    appointmentUpdate() 
    {

            this.isSubmit=true; // Kaydet butonunu pasif yap
            this.spinMessage=3;// Güncelleniyor
            this.is_data_load= false // Spin aktif et

            //Trainer seçildi ise
          if (this.$v.$invalid) 
          {
            // console.log("validation doğrulanmadı")
              return;
          }                 


          let appointmentEditRequest ={
            TimeTableId: this.appointment.time_table_id,
            SubjectTitle: this.appointment.title,
            CompanyBranchId:this.appointment.company_branch_id,
            Description:this.appointment.definition== null ? "":this.appointment.definition,
            TrainerProductId:this.appointment.trainer_product_id,
            TrainerId: this.appointment.trainer_id,
            ActivityId:this.appointment.activity_id,
            RoomId:this.appointment.room_id,
            Capacity:this.appointment.capacity,
            StartDate:this.appointment.start_date,
            EndDate:this.appointment.end_date, 
            StartTime: this.appointment.start_time,
            EndTime: this.appointment.end_time,
            GroupId:this.appointment.group_id,
            }

           let _appointmentEditRequest={
                TimeTableRequest:appointmentEditRequest,
                ActivityParticipants:this.ActivityParticipants.filter(x=>x.time_table_detail_id == "00000000-0000-0000-0000-000000000000"),
                InActivityParticipants:this.InActivityParticipants.filter(x=>x.time_table_detail_id !="00000000-0000-0000-0000-000000000000"),
                IsGroup:this.is_group
           };
             
              // console.log(
              //             {"is_group":_appointmentEditRequest.IsGroup },
              //             {"_appointmentEditRequest":_appointmentEditRequest.TimeTableRequest},
              //             {"ActivityParticipants":_appointmentEditRequest.ActivityParticipants}, 
              //             {"InActivityParticipants":_appointmentEditRequest.InActivityParticipants}
              //             )

                   this.$database.AppointmentService.appointment_shedule_edit_update(_appointmentEditRequest)    
                 .then((res) => {


                    if (res.IsSuccess === true || res.IsSuccess === 1) {
                          this.$functions.Messages.success(res.Result)        
                    }
                    else
                    {
                          this.$functions.Messages.error(res.Result)     
                    }

                        this.is_data_load= true 
                        this.hideModal();
                        this.sendToParentData(true);//işlme başarılı ise true
                  })
    },

    deleteAppointment() {

      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("Are you sure you want to delete the appointment"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes"),
        cancelButtonText: this.$t("No"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => 
      {
        if (result.value) 
        {
            // console.log({
            //   "time_table_id":this.appointment.time_table_id
            // },{
            //   "isGroup":this.is_group
            // })
            this.spinMessage=4   
            this.is_data_load= false 
             this.$database.AppointmentService.delete_multiple_appointments(this.appointment.time_table_id,this.is_group)    
                 .then((res) => {
                    this.is_data_load= true 
                     if(res.IsSuccess === true || res.IsSuccess === 1)
                     {
                        this.$functions.Messages.success(res.Result);
                     }else
                     {
                        this.$functions.Messages.error(res.Result);
                     }
                         this.hideModal();
                         this.sendToParentData(true);//işlme başarılı ise true
                 });
          //});
        }
      });


    },
    update() {},
  },
  watch: {

    is_group(val)
    {
      if(val == true)
      {
        this.$swal({
         title: this.$t("Are you sure?"),
         text: this.$t("Update co-created appointments too"),
         icon: "warning",
         showCancelButton: true,
         confirmButtonText: this.$t("Yes"),
         cancelButtonText: this.$t("No"),
         customClass: {
           confirmButton: "btn btn-primary",
           cancelButton: "btn btn-outline-danger ml-1",
         },
         buttonsStyling: false,
       }).then((result) => {

        if (result.isConfirmed != true) {
           // console.log({"Birlikte güncelle kabul edilmedi ":val})  
            this.is_group= false; // Kabul edilmez ise birlikte güncellemeyi aktif etme              
        }
        else
        {
           //console.log({"Birlikte güncelle kabul edildi ":val})
        }
      });  
      }
    },

    "room.room_id"(val)
     {
        // console.log({"watch selected_room.room_id":val});
        this.appointment.room_id = val; 
        var _optionsRoom=   this.optionsRoom.filter(x=>x.room_id== val);
        if(_optionsRoom !=null)
        {
          //console.log({"watch selected_room.capacity":_optionsRoom});
            this.appointment.capacity= _optionsRoom[0].capacity;      
        }
     },

    "appointment.room_id": function(val) {
         console.log({"watch appointment.room_id":val});

         //this.appointment.room_id=val;
         //this.room.room_id=val;
     },

   // randevu başlangıç saati değiştiğinde
    "appointment.start_time": function(val) {
      if (
        this.startTimeScala.map(function(e) {return e.key;
          }).indexOf(val) >=this.endTimeScala.map(function(e) {
            return e.key;
          }).indexOf(this.appointment.end_time) // başlangıç saati bitiş saatini geçtiğinde
      ) {
        this.appointment.end_time = this.endTimeScala[this.startTimeScala.map(function(e) {
              return e.key;
            }).indexOf(val) + 3
        ].key; // bitiş saatini ileri al
      }
    },
    //randevu bitiş saati değiştiğinde
    "appointment.end_time": function(val) {
      if (
        this.endTimeScala.map(function(e) {return e.key;})
          .indexOf(val) <=this.startTimeScala
          .map(function(e) {return e.key;})
          .indexOf(this.appointment.start_time) // bitiş saati başlangıç saatinden geri kaldığında
      ) {
        this.appointment.start_time = this.startTimeScala[
          this.endTimeScala.map(function(e) {return e.key;}).indexOf(val) - 3
        ].key; // başlangıç saatini geri al
      }
    },
  },
  validations: {


    appointment:{

        is_trainer_id(val, vm)
        { 
            console.log( {"vm.appointment.trainer_id":vm.appointment.trainer_id})
            return vm.appointment.trainer_id ==="00000000-0000-0000-0000-000000000000"  || vm.appointment.trainer_id == null
              ?false:true;
        },

        is_activity_id(val, vm)
        {           
            console.log( {"vm.appointment.activity_id":vm.appointment.activity_id})
            return vm.appointment.activity_id ==="00000000-0000-0000-0000-000000000000" || vm.appointment.activity_id == null           
              ?false:true;
        },

        start_date:{
            required:required
        },
        end_date:{
          required:required
        },
        end_time:{
             required:required
        },
        start_time:{
             required:required
        },
        title:{
           required:required
         },
      
    }
  },
  created() {
   // this.optionsTrainer.push(this.trainer)
  },
};
</script>

<style scoped>
.input-zoom {
  zoom: 1.15 !important;
}


    .opacity-1 {
      opacity: 1 !important;
    }

    .opacity-custom {
      opacity: 0.85 !important;
    }
</style>