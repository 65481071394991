<template>
  <b-modal
    ref="my-modal"
    id="modal-1"
    :title="modalTitle"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    header-bg-variant="primary"
    size="lg">
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="CalendarIcon" />
          <span>{{ $t("Planning") }}</span>
        </template>
        <b-row>
            <b-col md="6" sm="12">
              <!-- Trainer Seçim -->
              <b-form-group>
              <label for="title">{{ $t("Trainer") }}</label>
              <v-select
                v-model="trainer"
                :options="optionsTrainer"
                label="full_name"
                class="select-config"
                @blur="$v.trainer.trainer_id.$touch()">
                <span slot="no-options">{{
                  $t("No Suitable Trainers Found")
                }}</span>
              </v-select>
              <small
                v-if="isSubmit && !$v.trainer.checked"
                class="form-text text-danger">{{ $t("Trainer Selection Required") }}</small>
            </b-form-group>
          </b-col>
            <b-col md="6" sm="12" >
               <!-- Aktivite Seçim -->
            <b-form-group>
              <label for="title">{{ $t("Activity") }}</label>
              <v-select
                :clearable="false"
                v-model="activity"
                :options="optionsActivity"
                label="activity_name"
                class="select-config"
                :disabled="block_time === 'checked' ? true : false">
                <span slot="no-options">{{
                  $t("No Suitable Activity Found")
                }}</span>
              </v-select>
               <small
                v-if="isSubmit && !$v.activity.checked"
                class="form-text text-danger">{{ $t("Activity Selection Required") }}</small>
            </b-form-group>
            </b-col>
        </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="startTime"></label>{{ $t("Start Time") }}
                <v-select
                  v-model="appointment.start_time"
                  :options="startTimeScala"
                  value="key"
                  label="label"
                  :reduce="(item) => item.key"
                  class="select-config">
                </v-select>
                  <small  v-if="isSubmit && !$v.appointment.start_time.required"
                      class="form-text text-danger">{{ $t("Start time required") }}</small>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <label for="endTime"></label>{{ $t("End Time") }}
                <v-select
                  v-model="appointment.end_time"
                  :options="endTimeScala"
                  :reduce="(item) => item.key"
                  label="label"
                  class="select-config">
                </v-select>
                 <small  v-if="isSubmit && !$v.appointment.end_time.required"
                      class="form-text text-danger">{{ $t("End time required") }}</small>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
              <b-col>
               <!-- Randevu Başlık -->
              <b-form-group>
                <label for="title">{{ $t("Title") }}</label>
                <b-form-input
                  id="title"
                  v-model="appointment.title"
                  label="title"/>
                    <small  v-if="isSubmit && !$v.appointment.title.required"
                      class="form-text text-danger">{{ $t("Activity title required") }}</small>
              </b-form-group>
              <!-- Randevu Açıklama -->
              <b-form-group>
                <label for="definition">{{ $t("Description") }}</label>
                <b-form-textarea
                  id="defination"
                  v-model="appointment.definition"
                  placeholder=""
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group>
                <b-row >
                  <b-col sm="12" md="6">
                    <!-- Randevu Başlangıç Tarihi -->
                    <b-form-group>
                      <label for="start_date"></label>{{ $t("Start Date") }}
                      <b-form-input
                        id="startDate"
                        type="date"
                        disabled
                        v-model="appointment.start_date"
                        label="start_date" />
                     <small v-if="isSubmit && !$v.appointment.start_date.required"
                      class="form-text text-danger">{{ $t("Start date required") }}</small>
                    </b-form-group>
                  </b-col>
                  <b-col  sm="12" md="6">
                    <!-- Randevu Bitiş Tarihi -->
                    <b-form-group>
                      <label for="end_date"></label>{{ $t("End Date") }}
                      <b-form-input
                        id="endDate"
                        type="date"
                        disabled
                        v-model="appointment.end_date"
                        label="end_date"/>
                         <small v-if="isSubmit && !$v.appointment.end_date.required"
                      class="form-text text-danger">{{ $t("End date required") }}</small>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
              </b-col>
          </b-row>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>{{ $t("Participants") }}</span>
        </template>
        <b-row>
          <b-col>
            <div>
              <span class="text-success"> AKTİVİTEYE KATILANLAR</span>
            </div>
            <div>
              <b-form-input v-model="filter" class="d-inline-block mr-1" size="sm" :placeholder="$t('Search...')"/>
            </div>
            <div>
              <b-table
                hover
                :current-page="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aling="right"
                first-number
                last-number
                small="small"
                ref="refUserListTable"
                class="text-left position-relative"
                :items="ActivityParticipants"
                responsive="sm"
                sticky-header="300px"
                :fields="ActivityParticipantColumns"
                primary-key="id"
                :filter="filter"
                show-empty
                @filtered="onFiltered"
                :empty-text="$t('No Matching Creates Found')"
                :empty-filtered-text="
                  $t('There are no records matching your request!')
                "
              >
                <template #cell(actions)="data">
                  <b-button
                    size=""
                    style="padding:2px;"
                    class="m-0"
                    variant="outline-danger"
                    @click="removeCustomer(data.item, data.index)"
                  >
                    <feather-icon icon="XIcon" size="24"
                  /></b-button>
                </template>
              </b-table>
              <div class="mx-2">
                <b-row>
                  <div>
                    <small v-if="$i18n.locale === 'tr'" class="mt-1">
                      {{ totalRows }} kayıttan
                      {{ currentPage * perPage - perPage }}-{{
                        currentPage * perPage
                      }}
                      arası gösteriliyor
                    </small>
                  </div>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                    sm=""
                    class="d-flex align-items-right justify-content-right justify-content-sm-end radius0"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      aling="right"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0 r-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
          <b-col>
            <div>
              <span class="text-danger"> AKTİVİTEYE KATILMAYANLAR</span>
            </div>
            <div>
              <b-form-input
                v-model="filter2"
                class="d-inline-block mr-1"
                size="sm"
                :placeholder="$t('Search...')"
              />
            </div>
            <div>
              <b-table
                hover
                :current-page="currentPage2"
                :total-rows="totalRows2"
                :per-page="perPage2"
                aling="right"
                first-number
                last-number
                small="small"
                ref="refUserListTable"
                class="text-left position-relative"
                :items="InActivityParticipants"
                responsive="sm"
                sticky-header="300px"
                :fields="ActivityParticipantColumns"
                primary-key="id"
                :filter-included-fields="filterOn2"
                :filter="filter2"
                @filtered="onFiltered2"
                show-empty
                :empty-text="$t('No Matching Creates Found')"
                :empty-filtered-text="
                  $t('There are no records matching your request!')
                "
              >
                <template #cell(actions)="data">
                  <b-button
                    size=""
                    style="padding:2px;"
                    class="m-0"
                    variant="outline-success"
                    @click="addCustomer(data.item, data.index)"
                  >
                    <feather-icon icon="CheckIcon" size="24"
                  /></b-button>
                </template>
              </b-table>
              <div class="mx-2">
                <b-row>
                  <div>
                    <small v-if="$i18n.locale === 'tr'" class="mt-1">
                      {{ totalRows2 }} kayıttan
                      {{ currentPage2 * perPage2 - perPage2 }}-{{
                        currentPage2 * perPage2
                      }}
                      arası kayıt gösteriliyor
                    </small>
                  </div>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                    sm=""
                    class="d-flex align-items-right justify-content-right justify-content-sm-end radius0"
                  >
                    <b-pagination
                      v-model="currentPage2"
                      :total-rows="totalRows2"
                      :per-page="perPage2"
                      aling="right"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0 r-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-tab>

      <!-- Rezervasyon Sekmesi -->
      <b-tab>
        <template #title>
          <feather-icon icon="GridIcon" />
          <span>{{ $t("Rooms") }}</span>
        </template>
        <!-- Salon Seçim -->
        <b-form-group class="mt-1">
          <label for="title">{{ $t("Room") }}</label>
          <v-select
            v-model="room"
            :options="optionsRoom"
            label="room_name"
            class="select-config">
            <span slot="no-options">{{ $t("No Suitable Room Found") }}</span>
          </v-select>
        </b-form-group>

        <b-form-group class="mb-0">
          <label for="title">{{ $t("Capacity") }}</label>
          <b-form-input
            v-model="appointment.capacity"
            id="basicInput"
            :placeholder="$t('Capacity')"/>
        </b-form-group>
      </b-tab>
      <hr/>
      <template>
        <div class="row float-right">
          <div class="col">
            <b-button variant="warning" class="mr-1"  :disabled=!is_data_load   @click="cancel">
              {{ $t("Cancel") }}
            </b-button>
            <b-button variant="primary" :disabled=!is_data_load  @click="appointmentCreate()">
              {{ $t("Save") }}
            </b-button>
          </div>
        </div>
      </template>
        <b-button
          variant="outline-default"
          :disabled="true"
         :hidden=is_data_load
          style="position:absolute;display:block;top:40%;left:30%;"
          class="mr-1">
          <b-spinner
           style="width: 2rem; height: 2rem; "
           class="mr-1"/>
            <h3 style="color:#FF9F43;">
                <span style="textColor:7B70F1;" v-if="spinMessage==1">  {{ $t("Loading please wait") }} </span>
                <span style="textColor:7B70F1;" v-if="spinMessage==2">  {{ $t("Saving please wait") }} </span>
                <span style="textColor:7B70F1;" v-if="spinMessage==3">  {{ $t("Updating please wait") }} </span>
                <span style="textColor:7B70F1;" v-if="spinMessage==4">  {{ $t("Deleting please wait") }} </span>
                <span style="textColor:7B70F1;" v-if="spinMessage==5">  {{ $t("Loading participants please wait") }} </span>
            </h3>
        </b-button>
    </b-tabs>
  </b-modal>
</template>

<script>
import ParticipantsForNewModal from "@/views/facility/calendar/ParticipantsForNewModal";
import { required, email } from "vuelidate/lib/validators";
import { ref } from "@vue/composition-api";
import models from "@/models";

import flatPickr from "vue-flatpickr-component";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

import "flatpickr/dist/flatpickr.css";
import {
  BFormInput,
  BLink,
  BImg,
  BAvatar,
  BMediaAside,
  BFormCheckbox,
  BFormGroup,
  BFormCheckboxGroup,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCollapse,
  VBToggle,
  BTabs,
  BTab,
  BPopover,
  VBPopover,
  BFormTextarea,
  BModal,
  VBModal,
  BAlert,
  BBadge,
  BTable,
  BCard,
        BPagination,
        BSpinner

} from "bootstrap-vue";
import { mapActions, mapGetters, mapState } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import { startTimeScalaModel, endTimeScalaModel } from "@/models/timeScala";

export default {
  name: "ModalAppointment",
  components: {
    ParticipantsForNewModal,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BRow,
    BCol,
    BButton,
    BCardText,
    vSelect,
    BMediaAside,
    BAvatar,
    BCollapse,
    VBToggle,
    BTabs,
    BTab,
    BPopover,
    VBPopover,
    BFormTextarea,
    BModal,
    BAlert,
    BBadge,
    BTable,
    BCard,
    BPagination,
      flatPickr,
      BSpinner,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-popover": VBPopover,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      modalTitle: "",
      isAddNewLicenceSidebarActive: false,
      show: false,
      disabled: false,

      block_time: "not-checked",
      room: {
        room_id: "00000000-0000-0000-0000-000000000000",
        room_name: this.$t("Select"),
        is_active: true,
      },
      activity: {
        activity_id: "00000000-0000-0000-0000-000000000000",
        activity_name: this.$t("Select"),
        activity_time: 60,
        activity_type_id: "00000000-0000-0000-0000-000000000000",
      },
       trainer:null,
      // {
      //   trainer_id: "00000000-0000-0000-0000-000000000000",
      //   full_name: this.$t("Select"),
      // },
      customer: {
        person_id: "00000000-0000-0000-0000-000000000000",
        full_name: this.$t("Select"),
      },
      product: {
        trainer_product_id: "00000000-0000-0000-0000-000000000000",
        title: this.$t("Select"),
      },
      optionsRoom: [],
      optionsActivity: [],
      optionsTrainer: [],
      repeatCount: 1,
      selectedDays: [],
      instructorConfirmBaseData: [],
      distributor_person_list: [],
      company_branch_persons_data: [],
      //personData: [],
      // personBaseData: [],
      productListByCustomer: [],
      appointment: {
        company_branch_id:"",
        title: "",
        definition: "",
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        activity_id: null, //trainer calendar edit, handle event click ile gelecek düzenlenecek aktivite, aktivite idsi
        room_id: null, //trainer calendar edit, handle event click ile gelecek düzenlecek aktivite, salon idsi
        person_id: null,
        trainer_id: "00000000-0000-0000-0000-000000000000",
        trainer_name:null,
        trainer_product_id: null,
        activity_name:null,
        capacity: 12,
        activity_type_id: "00000000-0000-0000-0000-000000000000",
        time_table_id: "00000000-0000-0000-0000-000000000000",
      },
      ActivityParticipants: [], //Aktiviteye katılmış olan katılımcılar
      InActivityParticipants: [], //Aktiviteye katılmamış olan katılımcılar
      ActivityParticipantColumns: [
        {
          key: "time_table_detail_id",
          label: this.$t("time_table_detail_id"),
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "time_table_id",
          label: this.$t("time_table_id"),
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "person_id",
          label: this.$t("person_id"),
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "customer_product_sales_id",
          label: this.$t("time_table_detail_id"),
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "trainer_product_id",
          label: this.$t("time_table_detail_id"),
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "customer_name",
          label: this.$t("FULL NAME"),
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          tdClass: "text-center",
        },
      ],
      delayMinute: ["0", "10", "15", "20", "25", "30"],
      isPT: false,
      isSubmit: false,
      minute: "0",
      is_data_load:false, // Animasyon başlangıçta açık
      spinMessage:1 ,// 1 Yükleniyor //2 Kaydediliyor 3 Güncelleniyor 4 Siliniyor   Spin içinde kulanılacak olan mesajlar.
      startTimeScala: startTimeScalaModel,
      endTimeScala: endTimeScalaModel,
      participantsList: [],
      tableColumns: [
        // { key: "name", label: this.$t("Name"), sortable: true },
        // { key: "last_name", label: this.$t("Surname"), sortable: true },
        { key: "customer.full_name", label: this.$t("Katılımcı Adı Soyadı") },
        { key: "product.title", label: this.$t("Ürün Adı") },
        { key: "actions", label: this.$t("İşlem") },
      ],
      currentPage: 1,
      totalRows: 1,
      sortDesc: false,
      sortBy: "id",
      filter: null,
      perPageOptions: [10, 25, 50, 100],
      perPage: 25,
      filterOn: [],

      currentPage2: 1,
      totalRows2: 1,
      isSortDirDesc2: false,
      sortDesc2: false,
      sortBy2: "id",
      filter2: null,
      perPageOptions2: [10, 25, 50, 100],
      perPage2: 25,
      filterOn2: [],
      selectedActivityTime: null,
      //customerList: [],
      // customerListFiltered: []
         configStart: {
        dateFormat: "d.m.Y",
        locale: Turkish,
        minDate: "01.01.1940",
        maxDate: "today"
      },

      configEnd: {
        dateFormat: "d.m.Y",
        locale: Turkish,
       // maxDate: "today",
        minDate: "01.01.1940",
      },
    };
  },
  setup() {
    const appointment = ref();
    return {
      room: 1,
      appointment,
    };
  },
  methods: {

     sendToParentData(val)
      {
        this.$emit("newAppointmentData",val);
      },

    //Katılımcı ekle
    addCustomer(val, index) {
      this.InActivityParticipants.splice(index, 1);
      this.ActivityParticipants.push(val);
    },

    //Katılımcı kaldır
    removeCustomer(val, index) {
      this.ActivityParticipants.splice(index, 1);
      this.InActivityParticipants.push(val);
    },

    //katılımcı listesi arama aksiyonu
    onFiltered2(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems.length;
      this.currentPage2 = 1;
    },
    //katılımcı listesi arama aksiyonu
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    //main modelın kapatılması
    cancel() {
      this.hideModal();
    },
    cancelAddParticipants() {
      this.$refs["cancel-add-participants"].hide();
    },

    showPopover() {
      this.$root.$emit("bv::show::popover", "popover-button-1");
    },
    hidePopover() {
      this.$root.$emit("bv::hide::popover", "popover-button-2");
    },
    pushNotifications(status) {
      this.$emit("push-notification", status);
    },

    getRoomList() {
      this.$database.RoomService.get_room_company_branch(
        this.companyBranchId
      ).then((res) => {
        if (res.is_success === true || res.is_success === 1) {
          this.optionsRoom = res.result;
        }
      });
    },

    appointmentSheduleNew(company_branch_id, trainer_id) {

      this.spinMessage=1;
      this.is_data_load= false;

      this.$database.AppointmentService.appointment_shedule_new_request(
        company_branch_id,
        trainer_id
      ).then((res) => {
        if (res.IsSuccess === true || res.IsSuccess === 1) {
          if (res.Result.Trainers != null)
          {
            this.optionsTrainer = [];
            res.Result.Trainers.map((el) => {
              let _optionsTrainer = {};
              _optionsTrainer.trainer_id = el.TrainerId;
              _optionsTrainer.full_name = el.FirstName;
              this.optionsTrainer.push(_optionsTrainer);
            });
          }

          if (res.Result.Rooms != null) {

              // console.log({"Rooms" :res.Result.Rooms})


            this.optionsRoom = [];
            res.Result.Rooms.map((el) =>
            {
              let _room = {};
              _room.room_id = el.RoomId;
              _room.room_name = el.RoomName;
              _room.capacity= el.Capacity;
              this.optionsRoom.push(_room);
            });
          }

          this.is_data_load= true;
        }
      });
    },
    //Aktiviteye katılacak olan katılımcıların listesi
    inActivityParticipants(company_branch_id, activty_id, trainer_id) {

        this.spinMessage=5;
        this.is_data_load= false;


      this.ActivityParticipants=[], //Aktiviteye katılmış olan katılımcılar
      this.InActivityParticipants= [],


      this.$database.AppointmentService.in_activity_participants(
        company_branch_id,
        activty_id,
        trainer_id
      ).then((res) => {
        if (res.IsSuccess === true || res.IsSuccess === 1) {
         // console.log(res.Result);

          if (res.Result != null) {
            res.Result.map((ell) => {
              let InActivityParticipant = {};
              InActivityParticipant.time_table_detail_id =ell.TimeTableDetailId;
              InActivityParticipant.time_table_id = ell.TimeTableId;
              InActivityParticipant.person_id = ell.PersonId;
              InActivityParticipant.customer_name = ell.CustomerName;
              InActivityParticipant.customer_product_sales_id =ell.CustomerProductSalesId;
              InActivityParticipant.trainer_product_id = ell.TrainerProductId;
              this.InActivityParticipants.push(InActivityParticipant);
            });
          }
        }

        this.is_data_load= true;
      });
    },
     appointmentCreate() {

            // console.log(
            //       {"StartDate": this.appointment.start_date},
            //       {"EndDate": this.appointment.end_date},
            //       {"StartTime": this.appointment.start_time},
            //       {"EndTime": this.appointment.end_time})

              this.isSubmit=true;




            //Trainer seçildi ise
          if (this.$v.$invalid)
          {
             // console.log("validation doğrulanmadı")
              return;
          }

               this.spinMessage=2;
              this.is_data_load= false; // Animasyon başlasın


          let appointmentEditRequest ={
            TimeTableId: this.appointment.time_table_id,
            SubjectTitle: this.appointment.title,
            CompanyBranchId:this.appointment.company_branch_id,
            Description:this.appointment.definition,
            TrainerProductId:this.appointment.trainer_product_id,
            TrainerId: this.appointment.trainer_id,
            ActivityId:this.appointment.activity_id,
            RoomId:this.appointment.room_id,
            Capacity:this.appointment.capacity,
            StartDate:this.appointment.start_date,
            EndDate: this.appointment.end_date,
            StartTime: this.appointment.start_time,
            EndTime: this.appointment.end_time
            }

           let _appointmentEditRequest={
                TimeTableRequest:appointmentEditRequest,
                ActivityParticipants:this.ActivityParticipants.filter(x=>x.time_table_detail_id == "00000000-0000-0000-0000-000000000000"),
              //InActivityParticipants:this.InActivityParticipants.filter(x=>x.time_table_detail_id !="00000000-0000-0000-0000-000000000000"),
           };

              // console.log(
              //           {"TimeTableRequest":_appointmentEditRequest.TimeTableRequest},
              //           {"ActivityParticipants":_appointmentEditRequest.ActivityParticipants})

                this.$database.AppointmentService.appointment_shedule_new_create(_appointmentEditRequest)
                .then((res) => {
                    if (res.IsSuccess === true || res.IsSuccess === 1) {
                        this.$functions.Messages.success(res.Result)
                    }
                    else
                    {
                          this.$functions.Messages.error(res.Result)
                    }


                    this.hideModal();
                    this.sendToParentData(true);

                      this.is_data_load= true;
                })



    },

    showModal()
    {

       this.room= {
        room_id: "00000000-0000-0000-0000-000000000000",
        room_name: this.$t("Select"),
        capacity:5,
        is_active: true,
      };
      this.activity= {
        activity_id: "00000000-0000-0000-0000-000000000000",
        activity_name: this.$t("Select"),
        activity_time: 60,
        activity_type_id: "00000000-0000-0000-0000-000000000000",
      },
     this.trainer = {
        trainer_id: "00000000-0000-0000-0000-000000000000",
        full_name: this.$t("Select"),
      };

      this.appointment.title=null;
      this.appointment.definition=null;

      this.$refs["my-modal"].show();
    //  console.log({"appointment.ompany_branch_id":this.appointment.company_branch_id})
      this.appointmentSheduleNew(this.appointment.company_branch_id, this.appointment.company_branch_id);
      this.$store.state.app.loading = false // Genel spin kapat

      this.modalTitle = this.$t("Create Calendar Field") + ' (' + moment(this.appointment.start_date).format('DD.MM.YYYY') + ')';

    },
    hideModal() {
      this.ActivityParticipants = [];//Aktiviteye katılmış olan katılımcılar
      this.InActivityParticipants = []; //Aktiviteye katılmamış olan katılımcılar
      this.optionsTrainer = [];
      this.$refs["my-modal"].hide();
    },

  },
  watch: {

    ActivityParticipants(val) {
      this.totalRows = val.length;
    },
    InActivityParticipants(val) {
      this.totalRows2 = val.length;
    },

    room(val)
    {
      this.appointment.room_id = val.room_id; // Seçilen eğitmen_id  bilgisini Appointment e ekle
      this.appointment.capacity= val.capacity;
    },

    //eğitmen değiştiği an aktivite listesi de değişebilmeli
    trainer(val)
    {
      //console.log({"Trainer Id": val.trainer_id});

      this.optionsActivity = [];
      this.appointment.trainer_id = val.trainer_id; // Seçilen eğitmen_id  bilgisini Appointment e ekle
      this.activity = {
        activity_id: "00000000-0000-0000-0000-000000000000",
        activity_name: this.$t("Select"),
        activity_time: 60,
        activity_type_id: "00000000-0000-0000-0000-000000000000",
      };
      //Eğer bir Trainer seşildi ise Trainer in activitelerini listele
      if (val.trainer_id !== "00000000-0000-0000-0000-000000000000") {
        this.$database.TrainerService.list_trainer_activities(
          this.appointment.company_branch_id,
          val.trainer_id
        ).then((res) => {
          if (res.IsSuccess === true || res.IsSuccess === 1) {
            res.Result.map((el) => {
              let activity = {
                activity_id: el.ActivityId,
                activity_name: el.Name,
              };
              this.optionsActivity.push(activity);
            });
          }
        });


      }

    //  console.log({ Activity: this.optionsActivity });
    },

    activity(val) //Activity değiştiğinde Activity e katılacak katılımcı listesi değişmeli
    {

      // console.log(val)
      this.appointment.activity_id= val.activity_id;

      // console.log({"Trainer Id": this.appointment.trainer_id },
      //              {"Activity Id": this.appointment.activity_id });
      //AktivityId boş değil ise activitenin katılımcılarını listele
      if (val.activity_id != "00000000-0000-0000-0000-000000000000") {
       //console.log({ "Activity Val": val.activity_id });
        this.inActivityParticipants(this.appointment.company_branch_id,val.activity_id,this.appointment.trainer_id
        );
      }
    },

    participantsList(val) {
      this.totalRows2 = val.length;
    },

    // randevu başlangıç saati değiştiğinde
    "appointment.start_time": function(val) {
      // console.log({"appointment.start_time": val});
      if (
        this.startTimeScala.map(function(e) {return e.key;
          }).indexOf(val) >=this.endTimeScala.map(function(e) {
            return e.key;
          }).indexOf(this.appointment.end_time) // başlangıç saati bitiş saatini geçtiğinde
      ) {
        this.appointment.end_time = this.endTimeScala[this.startTimeScala.map(function(e) {
              return e.key;
            }).indexOf(val) + 3
        ].key; // bitiş saatini ileri al
      }
    },
    // // randevu bitiş saati değiştiğinde
    "appointment.end_time": function(val) {
      // console.log({"appointment.end_time": val});
      if (
        this.endTimeScala.map(function(e) {return e.key;}).indexOf(val) <=this.startTimeScala.map(function(e) {return e.key;})
          .indexOf(this.appointment.start_time) // bitiş saati başlangıç saatinden geri kaldığında
      ) {
        this.appointment.start_time = this.startTimeScala[this.endTimeScala.map(function(e) {return e.key;}).indexOf(val) - 3
        ].key; // başlangıç saatini geri al
      }
    },
  },
  validations: {
 trainer: {
      //yazdığım custom filter
        checked(val, vm) {
          //lütfen seçiniz kısmında gelen default id var ise false dön yani seçim yaptırmaya zorla
          return  vm.trainer.trainer_id === "00000000-0000-0000-0000-000000000000"
            ? false : true;
        },
     },
     activity:{
         checked(val, vm) {
          //lütfen seçiniz kısmında gelen default id var ise false dön yani seçim yaptırmaya zorla
          return vm.activity.activity_id === "00000000-0000-0000-0000-000000000000"
              ? false : true;
      }
    },
    appointment:{



        start_date:{
            required:required
        },
        end_date:{
          required:required
        },
        end_time:{
             required:required
        },
        start_time:{
             required:required
        },
        title:{
           required:required
         },

    }
  },
  created() {
     this.optionsActivity= [],
     this.trainer_id= "00000000-0000-0000-0000-000000000000"; //TrainerId null yap

    this.appointment.company_branch_id = this.$store.getters[
      "auth/userInfo"
    ].company_branch_id;
  },
};
</script>

<style>
#modal-1___BV_modal_title_ {
  color: #fff;
}
#modal-list-parti___BV_modal_title_ {
  color: #fff !important;
}
#modal-add-parti___BV_modal_title_ {
  color: #fff !important;
}
</style>
