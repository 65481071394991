<template>
  <!-- RANDEVU DÜZENLEME KATILIMCI LİSTESİ - BELİRLENEN RANDEVU İÇİN -->
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg sidebar-participants"
    shadow
    backdrop
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    no-header
    right
  >
    <template #default="{ hide }">
      <form-wizard
        title="Katılımcı Listesi"
        subtitle="null"
        shape="square"
        class="custom-form-wizard-classes"
      >
        <div class="participant-title mb-2">
          <b class="text-white custom-padding-badge bg-primary rounded">{{personData.length}} / {{capacity}}</b> <span class="custom-padding-badge">{{$t('Participants Available')}}</span>
        </div>
        <b-card no-body class="mb-0 participant-list">
            <div class="m-2">
              <!-- Table Top -->
              <b-row>
                <!-- Search -->
                <b-col cols="8" md="8">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                      v-model="filter"
                      class="d-inline-block mr-1"
                      :placeholder="$t('Search For Participants...')"
                    />
                  </div>
                </b-col>
                  <b-col cols="4" md="4" v-if="time_table_infos.capacity != personData.length">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                    v-b-modal.modal-primary
                    variant="success"
                    @click="getCustomerList(activity_id, trainer_id)">
                      {{$t('Add Participant')}}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-table
              hover
              ref="refUserListTable"
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPage"
              :items="personData"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              show-empty
              :empty-text="$t('No Matching Creates Found')"
              :empty-filtered-text="$t('There are no records matching your request!')"
              :sort-desc.sync="isSortDirDesc"
            >
              <template #cell(actions)="data">
                <template v-if="isMobile === true">
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item
                      @click="cancelAppointment(data.item.person_id)"
                    >
                      <feather-icon icon="XCircleIcon" />
                      <span class="align-middle ml-50">{{ $t("Listeden Kaldır") }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>

                <template v-if="isMobile === false">
                  <b-button
                    size="sm"
                    class="mr-1"
                    variant="outline-danger"
                    @click="cancelAppointment(data.item.person_id)"
                    >{{ $t("Listeden Kaldır") }}
                    </b-button>
                </template>
              </template>
            </b-table>

            <div class="mx-2 mb-2">
              <b-row>
                <!-- <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col> -->
                <b-col
                  cols="6"
                  sm=""
                  class="
                    d-flex
                    align-items-left
                    justify-content-right justify-content-sm-start
                    radius
                    0
                  "
                >

                </b-col>
                <small v-if="$i18n.locale === 'tr'" class="mt-1"
                  >içerisindeki {{ totalRows }} kayıttan {{ currentPage * 1 }}-{{
                    currentPage * perPage
                  }}
                  arası ({{ currentPage }}.sayfa) gösteriliyor
                </small>
                <small v-if="$i18n.locale === 'en'" class="mt-1">showing {{currentPage*1}}-{{currentPage*perPage}} (page {{currentPage}}) of {{totalRows}} records</small>
                <!-- Pagination -->
                <b-col
                  cols="6"
                  sm=""
                  class="
                    d-flex
                    align-items-right
                    justify-content-right justify-content-sm-end
                    radius
                    0
                  "
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aling="right"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0 r-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card>
          <b-modal
            ref="add-participant"
            id="modal-primary"
            size="xl"
            ok-only
            ok-title="Accept"
            modal-class="modal-primary"
            centered
            :title="selectProduct ? 'Ürün Seç' : 'Katılımcı Seç'"
            no-close-on-backdrop
            hide-footer
          >
        <b-card no-body class="mb-0" v-if="!selectProduct">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage2"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions2"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Show") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter2"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
            </div>
          </b-col>
        </b-row>
      </div>
            <b-row>
                 <b-col>

   <b-table
        hover
        ref="refUserListTable"
        class="position-relative"
        :per-page="perPage2"
        :current-page="currentPage2"
        :items="personBaseData"
        responsive
        :fields="tableColumns2"
        primary-key="id"
        :sort-by.sync="sortBy2"
        :filter="filter2"
        :filter-included-fields="filterOn2"
        @filtered="onFiltered2"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :empty-filtered-text="$t('There are no records matching your request!')"
        :sort-desc.sync="isSortDirDesc2"
      >
        <template #cell(actions)="data">
          <template v-if="isMobile === false">
            <b-button
              class="mr-1"
              variant="primary"
              @click="selectParticipant(data.item.person_id)"
              >{{ $t("Katılımcı Seç") }}</b-button
            >
          </template>
        </template>
      </b-table>
                 </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col cols="6"
               sm=""
            class="
              d-flex
              align-items-left
              justify-content-left justify-content-sm-start
              ml-2
              radius
              0
            ">
            <small v-if="$i18n.locale === 'tr'" class="mt-1"
            >içerisindeki {{ totalRows2 }} kayıttan {{ currentPage2 * 1 }}-{{
              currentPage2 * perPage2
            }}
            arası ({{ currentPage2 }}.sayfa) gösteriliyor
          </small>
          <small v-if="$i18n.locale === 'en'" class="mt-1">showing {{currentPage2*1}}-{{currentPage2*perPage2}} (page {{currentPage2}}) of {{totalRows2}} records</small>
              </b-col>
                <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-right
              justify-content-right justify-content-sm-end
              radius
              0
              mr-1
            "
          >
            <b-pagination
              v-model="currentPage2"
              :total-rows="totalRows2"
              :per-page="perPage2"
              aling="right"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
            </b-row>
            </b-card>

            <!-- ürün seç -->
            <b-card no-body class="mb-0" v-if="selectProduct">
            <div class="m-2">
                    <!-- Table Top -->
                    <b-row>
                      <!-- Per Page -->
                      <b-col
                        cols="12"
                        md="12"
                        class=" align-items-center justify-content-start mb-1 mb-md-0"
                      >
                      <!-- {{selectedCustomer}} -->
                          <b-form-group>
                          <label for="title">{{ $t("Seçilen Müşteri") }}</label>
                          <b-form-input
                            v-model="selectedCustomer.full_name"
                            disabled
                            id="title"
                            label="title"
                          />
                        </b-form-group>
                        <!-- {{product}} -->
                        <b-form-group>
                          <label class="mt-1" for="title">{{ $t("Ürün") }}</label>
                          <v-select
                              :clearable="false"
                              v-model="product"
                              :options="productListByCustomer"
                              label="extraTitle"
                              class="select-config d-block"
                              @blur="$v.trainer.trainer_id.$touch()"
                          >
                            <span slot="no-options">{{ $t('No Suitable Products Found') }}</span>
                          </v-select>
                        </b-form-group>
                        <!-- saveCustomer  -->
                        <hr>
                          <template>
                          <div class="row float-right">
                            <div class="col">
                              <b-button variant="warning" class="mr-1" @click="cancelAddParticipants">
                                {{ $t("Cancel") }}
                              </b-button>
                              <!-- katılımcıyı kaydet -->
                              <b-button variant="primary" @click="saveAddParticipants">
                                {{ $t("Save") }}
                              </b-button>
                            </div>
                          </div>
                        </template>
                      </b-col>
                    </b-row>
            </div>
            </b-card>
          </b-modal>
      <template>


</template>

        <template slot="footer" slot-scope="props">
          <div class="wizard-footer-left">
            <b-button
              size="md"
              class="mr-1 custom-back-button btn btn-primary"
              @click="hide"
            >
              {{ $t("Back") }}
            </b-button>
          </div>
        </template>
      </form-wizard>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
  BSidebar} from "bootstrap-vue";
import vSelect from "vue-select";
import DataBase from "@/services/DataBase.js";
import Ripple from "vue-ripple-directive";
import { FormWizard, TabContent } from "vue-form-wizard";
import moment from 'moment';

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
  BSidebar,
    vSelect,
    FormWizard
  },

  data() {
    return {
      time_table_infos: [],
      isEffect: false,
      customer: {
        person_id: "00000000-0000-0000-0000-000000000000",
        full_name: this.$t("Select"),
        email: ''
      },
      personBaseData: [],
      tableColumns: [
        // { key: "name", label: this.$t("Name"), sortable: true },
        // { key: "last_name", label: this.$t("Surname"), sortable: true },
        { key: "full_name", label: this.$t("FULL NAME") },
        { key: "email", label: this.$t("E-mail") },
        { key: "product_title", label: this.$t("Product Title")},
        { key: "actions", label: this.$t("TRANSACTIONS") },
      ],
      currentPage: 1,
      totalRows: 1,
      isSortDirDesc: false,
      sortDesc: false,
      sortBy: "id",
      filter: null,
      perPageOptions: [10, 25, 50, 100],
      perPage: 25,

      filterOn: [],

      locale: this.$i18n.locale,
      personData: [],

      selected: "tüm",
      options: [
        { text: this.$t("All Customers"), value: "tüm" },
        { text: this.$t("Active Customers"), value: "aktif" },
        { text: this.$t("Deactive Customers"), value: "deaktif" },
      ],
      companyBranchID: "",
      distributor_person_list: [],
      company_branch_persons_data: [],


      //participants grid table için
      currentPage2: 1,
      totalRows2: 1,
      isSortDirDesc2: false,
      sortDesc2: false,
      sortBy2: "id",
      filter2: null,
      perPageOptions2: [10, 25, 50, 100],
      perPage2: 25,

      filterOn2: [],

      locale: this.$i18n.locale,


      tableColumns2: [
        // { key: "name", label: this.$t("Name"), sortable: true },
        // { key: "last_name", label: this.$t("Surname"), sortable: true },
        { key: "full_name", label: this.$t("CUSTOMER FULL NAME") },
        { key: "email", label: this.$t("E-mail") },
        { key: "actions", label: this.$t("TRANSACTIONS") },
      ],

      productListByCustomer: [],
      selectProduct: false,
      selectedCustomer: {},
      product: this.$t("Select"),
    };
  },
    model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    time_table_id: String,
    activity_id: String,
    trainer_id: String,
    capacity: Number
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.personData
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    personData(val) {
      this.totalRows = val.length;
    },
    time_table_id(val){
      this.getParticipantsData()
    },
    personBaseData(val){
      this.totalRows2 = val.length
    },
    isEffect(val){
      this.getParticipantsData()
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.personData.length;
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFiltered2(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems.length;
      this.currentPage2 = 1;
    },
    //randevu iptal etme, time table detay id'sine göre
    cancelAppointment(_person_id){
        this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t('Katılımcıyı Listeden Kaldırmak İstediğinize Emin Misiniz?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.personData = this.personData.filter(el => el.person_id !== _person_id)
          let prevPersonDataLength = this.personData.length;
          this.personData = this.personData.filter(el => el.person_id !== _person_id);
          if (this.personData.length !== prevPersonDataLength - 1) {
            this.$functions.Messages.success('Katılımcı Listeden Başarılı Bir Şekilde Kaldırıldı.');
            this.isEffect = !this.isEffect
            this.totalRows = this.personData.length;
            this.$emit('updateParticipantsList', this.personData)
          } else {
            this.$functions.Messages.error('Katılımcı Listeden Kaldırılamadı.');
          }
        }
      })
    },

    //seçilen aktivitiye ait ürünlere sahip müşterileri getir.
    getCustomerList(activity_id, trainer_id){
      //iki dizinin elemn farkı için
      const differenceArrayFilter = (firstArray, secondArray) => {
        return firstArray.filter(firstArrayItem =>
            !secondArray.some(
                secondArrayItem => firstArrayItem.person_id === secondArrayItem.person_id
            )
        );
      };

      this.personBaseData = []

      // console.log("Hocanın Id si" + this.trainer.trainer_id)
      this.selectProduct = false

      this.$database.AppointmentService.get_customer_by_activity_id_product(
          activity_id, trainer_id

      ).then(res => {
        if(res.is_success === true || res.is_success === 1){
          const results = differenceArrayFilter(res.result, this.personData)
          results.map(el => {
            this.personBaseData.push({
              ...el,
              full_name: el.name + ' ' + el.last_name
            })
          })
        }
      })
    },
 //müşteriye ait ürünleri getirir, ürünün aktivite id si seçilen aktivite id ye eşitse döner.
    async getProductListByCustomer(person_id){
      this.product = this.$t('Select')
      this.productListByCustomer = []
      await this.$database.AppointmentService.get_customer_products_for_select(
        person_id,
        this.trainer_id,
        this.$store.getters["auth/userInfo"].company_branch_id
      ).then(res => {
        if(res.is_success === true || res.is_success === 1){
          res.result.map(el => {
            if(el.activity_id === this.activity_id){
              var end = moment(el.expiry_date)
              var start = moment(new Date())
              var diff = end.diff(start, 'days')
              if((diff >= 0 || el.expiry_date == null)){
                this.productListByCustomer.push({
                  ...el,
                  extraTitle:
                  el.expiry_date === null ? el.title + ' (Paketin Süre Erme Tarih Sınırı Yok)' : el.title + ' - (Paketin Sona Ermesine ' + diff + ' ' + this.$t('Gün Kaldı)')
                })
              }
            }
          })
        }
      })
    },
    selectParticipant(person_id){
      this.personBaseData.map(el => {
        if(el.person_id == person_id){
          this.selectedCustomer = {...el, full_name: el.name + ' ' + el.last_name}
        }
      })
      this.getProductListByCustomer(person_id)
      this.selectProduct = true
    },
    cancelAddParticipants(){
      this.$refs["add-participant"].hide();
    },
    saveAddParticipants(){
      if(this.selectedCustomer.person_id !== '00000000-0000-0000-0000-000000000000' && this.product.trainer_product_id !== '00000000-0000-0000-0000-000000000000' && this.product !== this.$t('Select')){
        this.personData.push({
          full_name: this.selectedCustomer.full_name,
          email: this.selectedCustomer.email,
          product_title: this.product.title,
          person_id: this.selectedCustomer.person_id,
          trainer_product_id: this.product.trainer_product_id,
          customer_product_sales_id: this.product.customer_product_sales_id
        })
        this.$functions.Messages.success('Katılımcı ekleme başarılı')
        this.$emit('updateParticipantsList', this.personData)
        this.$refs["add-participant"].hide();
      }else {
        if(this.selectedCustomer.person_id === '00000000-0000-0000-0000-000000000000' && this.product.trainer_product_id !== '00000000-0000-0000-0000-000000000000'){
          this.$functions.Messages.error('Müşteri Seçimi Yapmalısınız')
        }else if(this.product.trainer_product_id === '00000000-0000-0000-0000-000000000000'  && this.selectedCustomer.person_id !== '00000000-0000-0000-0000-000000000000'){
          this.$functions.Messages.error('Ürün Seçimi Yapmalısınız')
        }else {
          this.$functions.Messages.error('Müşteri ve Ürün Seçimi Yapmalısınız')
        }
      }
    }
  },
  created() {
    console.log("Participants")
    // this.$route.meta.breadcrumb[0].title = 'newTitle'
    this.companyBranchID = this.$store.getters["auth/userInfo"].company_branch_id
  },
};
</script>

<style lang="scss" src="@/styles/scss/participants.scss"></style>
<style lang="scss" src="@/styles/scss/participantsScoped.scss" scoped></style>
