<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- TAKVİM -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
                 <!--FİLTRELEME--> 
                <div class="row filter-row">
                    <div class="d-flex align-items-center col-md-1 col-sm-12 mb-1" style="min-width:120px;">
                        <b-media-aside class="toggle-button down-icon" v-b-toggle.scheduler-filter>
                            <b-avatar size="36" variant="light-primary">
                                <feather-icon size="18" icon="ArrowDownIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <span class="filter-text">{{ $t('Filter') }}</span>
                    </div>
                    <div class="col align-items-left col-md-10  col-sm-12">
                        <b-collapse id="scheduler-filter">
                            <div class="row">
                                <div class="col-md-4 col-sm-12 mb-1" style="min-width:250px;">
                                    <v-select v-model="trainer" label="full_name" class="select-config" @onchange="onChangeTrainer($event)" :options="optionsTrainer" :placeholder="$t('ALL TRAINERS')" :clearable="false"></v-select>
                                </div>
                                <div class="col-md-4 col-sm-12 mb-1"  style="min-width:250px;">
                                    <v-select v-model="activity" :options="optionsActivity" label="activity_name" class="select-config" :placeholder="$t('ALL ACTIVITES')" :clearable="false"></v-select>
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                    </div>
                    <b-alert v-if="eventCount === 0"
                        v-height-fade
                        show
                        dismissible
                        fade
                        class="text-center"
                        variant="danger">
                      <div class="alert-body">
                          <span> {{ $t('No appointment records found') }} </span>
                      </div>
                    </b-alert>
                    <!-- TAKVİM -->
                    <full-calendar id="calendar" ref="refCalendar"  :options="calendarOptions" class="full-calendar" />
                </div>
        </div>
      </div>
    </div>
    <!-- TAKVİME RANDEVU EKLEMEK -->
    <NewModalAppointment @newAppointmentData="reLoadCalendar($event)" ref="refModal" @push-notification="pushNotification"/> 
    <!-- TAKVİMDEKİ RANDEVUYU DÜZENLEMEK -->
    <EditModalAppointment @editAppointmentData="reLoadCalendar($event)"  ref="refEditModal" @push-notification="pushNotification"/>
    <AppointmentSheduleMultiNew @newMultiAppointmentData="reLoadCalendar($event)"  ref="refMultiNewModal"/>
  </div>
</template>

<script>
    
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import NewModalAppointment from '@/views/facility/calendar/components/AppointmentSheduleNew.vue'
import EditModalAppointment from '@/views/facility/calendar/components/AppointmentSheduleEdit.vue'
import AppointmentSheduleMultiNew  from '@/views/facility/calendar/components/AppointmentSheduleMultiNew.vue'
import vSelect from 'vue-select'
import { heightFade } from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'

import {
  BFormSelect,
  BAvatar,
  BAlert,
  BCollapse,
  BMediaAside,
  VBToggle,
} from 'bootstrap-vue'
import calendar from '../../../router/routes/calendar'

export default {
  name: 'calender_edit',
  components: {
    NewModalAppointment,
    EditModalAppointment,
    AppointmentSheduleMultiNew,
   FullCalendar, // make the <FullCalendar> tag available
    BFormSelect,
    BAlert,
    vSelect,
    BCollapse,
    BMediaAside,
    BAvatar,
    VBToggle,
  },
  directives: {
    heightFade,
    Ripple,
    'b-toggle': VBToggle
  },
  data() {
    
    const language = this.$store.getters['root/language']
    return {
       locale: this.$i18n.locale, 
        options: [],
      optionsTrainer: [],
        trainer:
        {
            trainer_id: "00000000-0000-0000-0000-000000000000",
            full_name: this.$t('Select To Filter Trainers')
        },
      instructorConfirmBaseData: [],
        optionsActivity: [],
        activity:
        {
            activity_id: "00000000-0000-0000-0000-000000000000",
            activity_name: this.$t('Select To Filter Activities')
        },
      thisFilteredTempData: [],
      start_date:null,
      end_date:null,   
      appModalData: "", // Modal kapanır iken gelen veri
      calendarOptions:
     {           
        firstDay: 1, //takvim gününün hangi günde başlayacağını set eder; Sunday=0, Monday=1, Tuesday=2...
        stickyFooterScrollbar: true,
        contentHeight: 'auto', //takvimin görüş alanının yüksekliğini ayarlar, tarihler yukarıdan kaybolmamasını desteklesin diye kullanıyorum
        stickyHeaderDates: true, //görünüm alanının başlıkların sabitlenmesi için
        allDaySlot: false, //sol taraf tüm gün boyu seçeneği aktif
        allDayText: this.$t('All-Day'), //sol taraf tüm gün boyu seçeneği yazısı
        html: true,
        slotMinTime: '00:00:00', //Determines the first time slot that will be displayed for each day.
          slotMaxTime: '24:00:00', //Determines the last time slot that will be displayed for each day. In line with the discussion about the Event object, it is important to stress that this should be specified as an exclusive end time.
          slotEventOverlap:false, //eventlern yan yana oluşmasını sağlar
        plugins: [dayGridPlugin,timeGridPlugin,interactionPlugin,] ,// needed for dateClick
        headerToolbar: {
          left: 'myPrev,myNext,myToday',
          center: 'title',
          right: 'myMounth,myWeek,myDay,appointment_schedule',
        },
      //  filterResourcesWithEvents:true,
       // eventAfterAllRender:handleAfterAllRender(view),
          events: [],
        
        loaded:this.loaded,
        initialView: 'timeGridWeek',    
        editable: true,
        selectable: true,
        selectMirror: false, //seçim yapıldığında arkaplanda nerenin seçildiğini blok şeklinde gösterir
        dayMaxEvents: true,
        initialEvents: this.handleInitialEvents ,
        select: this.handleDateSelect,
        selectAllow: this.handleDateSelectAllow,
        selectOverlap: function (event) 
        {
          //bloklanmıs alanın ustunden tekrar secmeyi onler (ust uste gelmesi)
          return event.rendering === 'background'
        },
        customButtons: { myPrev: { text: 'Geri', click:this.prvBtn, icon:'chevron-left' },
            myNext: {text: 'İleri', click:this.nextBtn, icon:'chevron-right' },
            myWeek: { text: this.$t('Week'),click: this.myWeek,},
            myMounth: { text: this.$t('Month'), click: this.myMonth,},
            myDay: {text: this.$t('Day'),click: this.myDay,},
            myToday: {text: this.$t('Today'),click: this.myToday,},
          appointment_schedule:
          {
            text:this.$t('Appointment Schedule'),
            click: this.appointment_schedule
          }
        },
        dayHeaderFormat: {
          weekday: 'long',
          day: 'numeric',
          omitCommas: true
        }, //takvimin günleri için 21 Pazar gibi
        weekends: true, //cumartesi, pazar dahil edilsin (default true)
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventContent: function (arg) {           
        },
        eventDidMount: function (elm)
        {
           // console.log({"eventDidMount":elm});
            if (elm.event.extendedProps.counter > 0) {
                let child = document.createElement("div");
                child.innerText =elm.event.extendedProps.counter
                child.className = 'event-badge'
                elm.el.appendChild(child); 
            }          
        },
        locale: language,
        timeFormat: 'H(:mm)',
         events:this.baseCalendarData,
        views: {
          dayGridMonth: {
            // name of view
            titleFormat: {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            },
            // other view-specific options here
          },
          dayGrid: {
            // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
          },
          timeGrid: {
            // options apply to timeGridWeek and timeGridDay views
          },
          week: {
            // options apply to dayGridWeek and timeGridWeek views
          },
          day: {
            // options apply to dayGridDay and timeGridDay views
          },
          },

        eventResizableFromStart: true,
        dragScroll: true,
        nowIndicator: true, //şu an ki zamanı takvimde kırmızı çizgi ile işaretler
        slotDuration: '01:00:00', //zaman aralıkları 1'er saat olsun
        slotLabelFormat: {
          //sol taraftaki etkinlik saat aralıklarını dakika 2 digit yapar 12 => 12.30
          hour: 'numeric',
          minute: '2-digit',
        },
        snapDuration: '01:00:00',
        },

      currentEvents: [],
      baseCalendarData: [],
      eventCount: null,
      counterForWeek: null,
      //params data
      companyBranchId: "",
        personId: "",

       filterStart:0
    }
  },
      methods: {

      onChangeTrainer(event)
      {
          console.log("Trainer Change çalıştı")
      },

      /*
      * Yeni güncellene Appointment Modal in  kapatılması ile birlikte oluşan event ile çalışır.
      * Takvimi tekrar yükler
      */

      reLoadCalendar(val)
      {
        //  console.log({"Donuş degeri : ": val})
          this.getSchedulerList(this.companyBranchId, this.trainer.trainer_id,  this.activity.activity_id,this.start_date, this.end_date)
          this.calendarOptions.events=this.baseCalendarData
      },

      calendarLoad(action) 
      {
        var calendar= this.$refs.refCalendar.getApi();

          switch(action) {
              case "prev":
               // console.log("Geri")
                calendar.prev();
                break;
              case "next":
               // console.log("İleri")
                calendar.next();
                break;
              case "day":
              // console.log("Gün")
                calendar.changeView('timeGridDay');
                break;
              case "week":              
               // console.log("Hafta")
                calendar.changeView('timeGridWeek');
                break;
              case "month":
               // console.log("Ay")
                calendar.changeView('dayGridMonth');
                break;  
              default:
              //   console.log("Bugün")
                calendar.today();
          }

           var cal  =   calendar.currentData.dateProfile.currentRange;   
          this.start_date = this.moment(cal.start).locale(this.locale).format('MM/DD/YYYY')    
          this.end_date = this.moment(cal.end).locale(this.locale).format('MM/DD/YYYY')   

          //console.log(this.locale)
          //console.log(this.start_date);
          //console.log(this.end_date);


          this.getSchedulerList(this.companyBranchId, this.trainer.trainer_id, this.activity.activity_id, this.start_date, this.end_date);
          this.calendarOptions.events=this.baseCalendarData
      },

      nextBtn(){this.calendarLoad("next")},
      prvBtn(){this.calendarLoad("prev") },
      myMonth(){this.calendarLoad("month") },
      myWeek(){this.calendarLoad("week")},
      myDay(){this.calendarLoad("day")},
      myToday(){this.calendarLoad("today")},
      appointment_schedule()
      {
         this.$refs.refMultiNewModal.showModal()
      },

    pushNotification(status) {
      if (status === 'GUNCELLENDI') {
        this.baseCalendarData = []
        this.calendarOptions.events = []
       this.getSchedulerList(this.companyBranchId, this.personId)
      }
    },
    handleWeekendsToggle() 
    {    
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },

    //Sayfa yüklenirken çalışıyor.
    handleInitialEvents(event) 
    {
      // this.$refs.refCalendar.fullCalendar('rerenderEvents') 
      // console.log({"companyBranchId = ": this.companyBranchId},
      //           {"personId = " : this.personId})  
       this.start_date = this.moment(event.start).locale(this.locale).format('MM/DD/YYYY');  //this.moment(event.start).locale(this.locale).format('L')
       this.end_date = this.moment(event.end).locale(this.locale).format('MM/DD/YYYY');      //this.moment(event.end).locale(this.locale).format('L')
       this.getSchedulerList(this.companyBranchId, this.trainer.trainer_id, this.activity.activity_id,this.start_date, this.end_date)
       this.calendarOptions.events=this.baseCalendarData
      //console.log({"Start  Date : ": this.start_date},
      //            {"End  Date : " : this.end_date});     
    },
    //EKLEMEK İÇİN AKTİVİTEYE TIKLANDIĞINDA
    handleDateSelect(selectInfo) {
      this.$refs.refModal.appointment.start_date = this.$functions.DateFormat(selectInfo.start, 'yyyy-mm-dd')
      //EKLENECEK RANDEVU BİTİŞ TARİHİ SEÇİLEN TARİH İLE BAŞLATMAK
      this.$refs.refModal.appointment.end_date = this.$functions.DateFormat(selectInfo.start, 'yyyy-mm-dd')
      //EKLENECEK RANDEVU BAŞLANGIÇ ZAMANI SEÇİLEN ZAMAN İLE BAŞLATMAK
      this.$refs.refModal.appointment.start_time = this.$functions.DateFormat(selectInfo.start, 'HH:MM:ss')
      //EKLENECEK RANDEVU BİTİŞ ZAMANI SEÇİLEN ZAMAN İLE BAŞLATMAK
      var dateManipulate = new Date(selectInfo.end);
      dateManipulate.setSeconds(dateManipulate.getSeconds() - 1);
      this.$refs.refModal.appointment.end_time = this.$functions.DateFormat(dateManipulate, 'HH:MM:ss')
      this.$refs.refModal.showModal()
    },

    //ÇOKLU TARİH SEÇİMLERİNİ YÖNETTİĞİM KISIM - SELECTALLOW PROPERTY İÇİN
    handleDateSelectAllow(selectInfo) {
      //END 00.00 DIŞINDAKİ NORMAL SEÇİMLER
      if (selectInfo.end.getHours() - selectInfo.start.getHours() > 1) {
        return false
      }//GÜN SONU İLE BİRLEŞEN SEÇİMLER , BAŞLANGICI 23 OLMAYAN, 1 SAAT AŞIMI YAŞAYAN
      else if (
          selectInfo.end.getHours() === 0 &&
          selectInfo.start.getHours() !== 23 &&
          selectInfo.start.getHours() - selectInfo.end.getHours() > 1
      ) {
        return false
      }//GÜN AŞIMI YAŞAYAN SEÇİMLER
          // else if (selectInfo.end.getDate() - selectInfo.start.getDate() >= 1)
          //   return false;

      //DİĞERLERİ
      else {
        return true
      }
    },

    //DÜZENLEMEK İÇİN AKTİVİTEYE TIKLANDIĞINDA
    handleEventClick(clickInfo) 
    {
       this.$refs.refEditModal.appointment.activity_id = clickInfo.event.extendedProps.activity_id    
       this.$refs.refEditModal.appointment.trainer_id = clickInfo.event.extendedProps.trainer_id
       this.$refs.refEditModal.appointment.time_table_id = clickInfo.event.extendedProps.time_table_id
       this.$refs.refEditModal.appointment.start_date = this.$functions.DateFormat(clickInfo.event.start, 'yyyy-mm-dd')
      // //DÜZENLENECEK RANDEVU BİTİŞ TARİHİ SEÇİLEN TARİH
       this.$refs.refEditModal.appointment.end_date = this.$functions.DateFormat(clickInfo.event.end, 'yyyy-mm-dd')
      // //DÜZENLENECEK RANDEVU BAŞLAMA ZAMANI SEÇİLEN ZAMAN
       this.$refs.refEditModal.appointment.start_time = this.$functions.DateFormat(clickInfo.event.start, 'HH:MM:ss')
      //DÜZENLENECEK RANDEVU BİTİŞ ZAMANI SEÇİLEN ZAMAN
      // alert(JSON.stringify(clickInfo.event))

       var dateManipulate = new Date(clickInfo.event.end);
       dateManipulate.setSeconds(dateManipulate.getSeconds() - 1);
       this.$refs.refEditModal.appointment.end_time = this.$functions.DateFormat(dateManipulate, 'HH:MM:ss')
       this.$refs.refEditModal.showModal()
    },

    handleEvents(events) 
    {
        this.currentEvents = events
    },


    //trainer_id ve company_branch_id ile planlanmıs time table activitylerini alicam
      async getSchedulerList(company_branch_id, trainer_id,activity_id, start_date, end_date)
      {
            //console.log({"companyBranchId = ": this.companyBranchId},
            //    { "traineId = ": trainer_id },
            //    { "activity_id": activity_id })

          this.baseCalendarData = [];
          await this.$database.SchedulerService.get_appointment_calendar_list(company_branch_id, trainer_id, activity_id,start_date, end_date)
          .then((res) => {
            if (res.IsSuccess === true || res.IsSuccess === 1) 
            {
                res.Result.map((el) => {
                    var dateManipulateEnd = new Date(el.EndDate);
                    if (el.EndDate.split('T')[1] === '23:59:59') {
                        dateManipulateEnd = dateManipulateEnd;
                    } else {
                        dateManipulateEnd.setSeconds(dateManipulateEnd.getMilliseconds() + 60);
                    }

                    this.baseCalendarData.push({
                        id: el.TimeTableId,
                        groupId: el.GroupId,
                        time_table_id: el.TimeTableId,
                        activity_color: el.ActivityColor,
                        title:el.SubjectTitle,
                        counter: el.Counter,
                        start: el.StartDate,
                        end: dateManipulateEnd,
                        definition: el.Definition,
                        trainer_product_id: el.TrainerProductId,
                        trainer_id: el.TrainerId,
                        activity_id: el.ActivityId,
                        activity_type_id: el.ActivityTypeId,
                        activity_name: el.ActivityName,
                        trainer_name: el.TrainerName,
                        trainer_lastname: el.TrainerName,
                        room_id: el.RoomId,
                        person_id: el.PersonId,
                        capacity: el.Capacity,
                        editable: false, //sağa sola taşınma özelliği
                        //aktiviteye göre
                        backgroundColor: el.ActivityColor,
                        borderColor: el.ActivityColor,
                        textColor: el.ActivityColor,
                        extendedProps: {
                            trainer_name: el.TrainerName
                        },
                        classNames: [el.TimeTableId]
                    })                  
                });
               // this.addBadge()
            }
          })
    },

      getActiveTrainers(company_branch_id, trainer_id)
      {
          let selectAllTrainer = {
              trainer_id: "00000000-0000-0000-0000-000000000000",
              full_name: this.$t('All Trainers')
          }
          this.optionsTrainer.push(selectAllTrainer);
          this.$database.TrainerService.get_active_trainers(
              company_branch_id,
              trainer_id
          ).then((res) =>
          {            
              if (res.IsSuccess === true || res.IsSuccess === 1) {
                  res.Result.map((el) =>
                  {
                          let _optionsTrainer = {
                              trainer_id : el.TrainerId,
                              full_name: el.FirstName
                          }
                          this.optionsTrainer.push(_optionsTrainer);
                  });
                this.trainer = selectAllTrainer;
              }
          });
      },

      getActivities(company_branch_id, trainer_id)
      {
          this.optionsActivity=[];
          let selectAllActivity = {
              activity_id: "00000000-0000-0000-0000-000000000000",
              activity_name: this.$t('All Activities')
          }
          this.optionsActivity.push(selectAllActivity);

          this.$database.ActivityService.appointment_filter_activities(
              company_branch_id,
              trainer_id
          ).then((res) => {
              if (res.IsSuccess === true || res.IsSuccess === 1) {
                  res.Result.map((el) => {
                      let _optionsActivity = {
                          activity_id: el.ActivityId,
                          activity_name: el.Name
                      }
                      this.optionsActivity.push(_optionsActivity);
                  });

                  this.activity = selectAllActivity
              }
          });

      }
  },
   mounted(){

       this.$refs["refModal"]



    
    // console.log(this.$refs["refModal"])
    // this.$refs["my-modal"].on("hidden.bs.modal", this.doSomethingOnHidden)
  },
  //sayfa oluşturulduğunda salonları, eğtitmenleri,aktiviteleri ve etkinlikleri çek
  created() {
    this.companyBranchId= this.$store.getters['auth/userInfo'].company_branch_id;
    this.personId = this.$store.getters['auth/userInfo'].person_id

     //console.log({"companyBranchId = ": this.companyBranchId},
     //            {"personId = " : this.personId})
     this.getActiveTrainers(this.companyBranchId, this.personId)
  },
  watch: {

      trainer(val)
      {
           //console.log({"companyBranchId = ": this.companyBranchId},
           //      {"traineId = " : val.trainer_id})

          this.getActivities(this.companyBranchId, val.trainer_id)
      },

      activity(val)
      {
          //console.log({ "companyBranchId = ": this.companyBranchId },
          //    { "activityId = ": val.activity_id })

          //Sayfa ilk yüklendiğinde çalışmaması için filterStart değişkeni ekledim
          if (this.filterStart > 0) {
              this.getSchedulerList(this.companyBranchId, this.trainer.trainer_id, this.activity.activity_id, this.start_date, this.end_date)
              this.calendarOptions.events = this.baseCalendarData
          }
              this.filterStart = 1;
      }
  },
}
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/trainerCalendar.scss">
</style>


