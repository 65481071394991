export const startTimeScalaModel = [
  {
    key: '00:00:00',
    label: '00:00'
  },
  {
    key: '00:14:00',
    label: '00:15'
  },
  {
    key: '00:30:00',
    label: '00:30'
  },
  {
    key: '00:45:00',
    label: '00:45'
  },
  {
    key: '01:00:00',
    label: '01:00'
  },
  {
    key: '01:15:00',
    label: '01:15'
  },
  {
    key: '01:30:00',
    label: '01:30'
  },
  {
    key: '01:45:00',
    label: '01:45'
  },
  {
    key: '02:00:00',
    label: '02:00'
  },
  {
    key: '02:15:00',
    label: '02:15'
  },
  {
    key: '02:30:00',
    label: '02:30'
  },
  {
    key: '02:45:00',
    label: '02:45'
  },
  {
    key: '03:00:00',
    label: '03:00'
  },
  {
    key: '03:15:00',
    label: '03:15'
  },
  {
    key: '03:30:00',
    label: '03:30'
  },
  {
    key: '03:45:00',
    label: '03:45'
  },
  {
    key: '04:00:00',
    label: '04:00'
  },
  {
    key: '04:15:00',
    label: '04:15'
  },
  {
    key: '04:30:00',
    label: '04:30'
  },
  {
    key: '04:45:00',
    label: '04:45'
  },
  {
    key: '05:00:00',
    label: '05:00'
  },
  {
    key: '05:15:00',
    label: '05:15'
  },
  {
    key: '05:30:00',
    label: '05:30'
  },
  {
    key: '05:45:00',
    label: '05:45'
  },
  {
    key: '06:00:00',
    label: '06:00'
  },
  {
    key: '06:15:00',
    label: '06:15'
  },
  {
    key: '06:30:00',
    label: '06:30'
  },
  {
    key: '06:45:00',
    label: '06:45'
  },
  {
    key: '07:00:00',
    label: '07:00'
  },
  {
    key: '07:15:00',
    label: '07:15'
  },
  {
    key: '07:30:00',
    label: '07:30'
  },
  {
    key: '07:45:00',
    label: '07:45'
  },
  {
    key: '08:00:00',
    label: '08:00'
  },
  {
    key: '08:15:00',
    label: '08:15'
  },
  {
    key: '08:30:00',
    label: '08:30'
  },
  {
    key: '08:45:00',
    label: '08:45'
  },
  {
    key: '09:00:00',
    label: '09:00'
  },
  {
    key: '09:15:00',
    label: '09:15'
  },
  {
    key: '09:30:00',
    label: '09:30'
  },
  {
    key: '09:45:00',
    label: '09:45'
  },
  {
    key: '10:00:00',
    label: '10:00'
  },
  {
    key: '10:15:00',
    label: '10:15'
  },
  {
    key: '10:30:00',
    label: '10:30'
  },
  {
    key: '10:45:00',
    label: '10:45'
  },
  {
    key: '11:00:00',
    label: '11:00'
  },
  {
    key: '11:15:00',
    label: '11:15'
  },
  {
    key: '11:30:00',
    label: '11:30'
  },
  {
    key: '11:45:00',
    label: '11:45'
  },
  {
    key: '12:00:00',
    label: '12:00'
  },
  {
    key: '12:15:00',
    label: '12:15'
  },
  {
    key: '12:30:00',
    label: '12:30'
  },
  {
    key: '12:45:00',
    label: '12:45'
  },
  {
    key: '13:00:00',
    label: '13:00'
  },
  {
    key: '13:15:00',
    label: '13:15'
  },
  {
    key: '13:30:00',
    label: '13:30'
  },
  {
    key: '13:45:00',
    label: '13:45'
  },
  {
    key: '14:00:00',
    label: '14:00'
  },
  {
    key: '14:15:00',
    label: '14:15'
  },
  {
    key: '14:30:00',
    label: '14:30'
  },
  {
    key: '14:45:00',
    label: '14:45'
  },
  {
    key: '15:00:00',
    label: '15:00'
  },
  {
    key: '15:15:00',
    label: '15:15'
  },
  {
    key: '15:30:00',
    label: '15:30'
  },
  {
    key: '15:45:00',
    label: '15:45'
  },
  {
    key: '16:00:00',
    label: '16:00'
  },
  {
    key: '16:15:00',
    label: '16:15'
  },
  {
    key: '16:30:00',
    label: '16:30'
  },
  {
    key: '16:45:00',
    label: '16:45'
  },
  {
    key: '17:00:00',
    label: '17:00'
  },
  {
    key: '17:15:00',
    label: '17:15'
  },
  {
    key: '17:30:00',
    label: '17:30'
  },
  {
    key: '17:45:00',
    label: '17:45'
  },
  {
    key: '18:00:00',
    label: '18:00'
  },
  {
    key: '18:15:00',
    label: '18:15'
  },
  {
    key: '18:30:00',
    label: '18:30'
  },
  {
    key: '18:45:00',
    label: '18:45'
  },
  {
    key: '19:00:00',
    label: '19:00'
  },
  {
    key: '19:15:00',
    label: '19:15'
  },
  {
    key: '19:30:00',
    label: '19:30'
  },
  {
    key: '19:45:00',
    label: '19:45'
  },
  {
    key: '20:00:00',
    label: '20:00'
  },
  {
    key: '20:15:00',
    label: '20:15'
  },
  {
    key: '20:30:00',
    label: '20:30'
  },
  {
    key: '20:45:00',
    label: '20:45'
  },
  {
    key: '21:00:00',
    label: '21:00'
  },
  {
    key: '21:15:00',
    label: '21:15'
  },
  {
    key: '21:30:00',
    label: '21:30'
  },
  {
    key: '21:45:00',
    label: '21:45'
  },
  {
    key: '22:00:00',
    label: '22:00'
  },
  {
    key: '22:15:00',
    label: '22:15'
  },
  {
    key: '22:30:00',
    label: '22:30'
  },
  {
    key: '22:45:00',
    label: '22:45'
  },
  {
    key: '23:00:00',
    label: '23:00'
  },
  {
    key: '23:15:00',
    label: '23:15'
  },
  {
    key: '23:30:00',
    label: '23:30'
  },
  {
    key: '23:45:00',
    label: '23:45'
  },
]

export const endTimeScalaModel = [
  {
    key: '00:14:59',
    label: '00:15'
  },
  {
    key: '00:29:59',
    label: '00:30'
  },
  {
    key: '00:45:59',
    label: '00:30'
  },
  {
    key: '00:59:59',
    label: '01:00'
  },
  {
    key: '01:14:59',
    label: '01:15'
  },
  {
    key: '01:29:59',
    label: '01:30'
  },
  {
    key: '01:44:59',
    label: '01:45'
  },
  {
    key: '01:59:59',
    label: '02:00'
  },
  {
    key: '02:14:59',
    label: '02:15'
  },
  {
    key: '02:29:59',
    label: '02:30'
  },
  {
    key: '02:44:59',
    label: '02:45'
  },
  {
    key: '02:59:59',
    label: '03:00'
  },
  {
    key: '03:14:59',
    label: '03:15'
  },
  {
    key: '03:29:59',
    label: '03:30'
  },
  {
    key: '03:44:59',
    label: '03:45'
  },
  {
    key: '03:59:59',
    label: '04:00'
  },
  {
    key: '04:14:59',
    label: '04:15'
  },
  {
    key: '04:29:59',
    label: '04:30'
  },
  {
    key: '04:44:59',
    label: '04:45'
  },
  {
    key: '04:59:59',
    label: '05:00'
  },
  {
    key: '05:14:59',
    label: '05:15'
  },
  {
    key: '05:29:59',
    label: '05:30'
  },
  {
    key: '05:44:59',
    label: '05:45'
  },
  {
    key: '05:59:59',
    label: '06:00'
  },
  {
    key: '06:14:59',
    label: '06:15'
  },
  {
    key: '06:29:59',
    label: '06:30'
  },
  {
    key: '06:44:59',
    label: '06:45'
  },
  {
    key: '06:59:59',
    label: '07:00'
  },
  {
    key: '07:14:59',
    label: '07:15'
  },
  {
    key: '07:29:59',
    label: '07:30'
  },
  {
    key: '07:44:59',
    label: '07:45'
  },
  {
    key: '07:59:59',
    label: '08:00'
  },
  {
    key: '08:14:59',
    label: '08:15'
  },
  {
    key: '08:29:59',
    label: '08:30'
  },
  {
    key: '08:44:59',
    label: '08:45'
  },
  {
    key: '08:59:59',
    label: '09:00'
  },
  {
    key: '09:14:59',
    label: '09:15'
  },
  {
    key: '09:29:59',
    label: '09:30'
  },
  {
    key: '09:44:59',
    label: '09:45'
  },
  {
    key: '09:59:59',
    label: '10:00'
  },
  {
    key: '10:14:59',
    label: '10:15'
  },
  {
    key: '10:29:59',
    label: '10:30'
  },
  {
    key: '10:44:59',
    label: '10:45'
  },
  {
    key: '10:59:59',
    label: '11:00'
  },
  {
    key: '11:14:59',
    label: '11:15'
  },
  {
    key: '11:29:59',
    label: '11:30'
  },
  {
    key: '11:44:59',
    label: '11:45'
  },
  {
    key: '11:59:59',
    label: '12:00'
  },
  {
    key: '12:14:59',
    label: '12:15'
  },
  {
    key: '12:29:59',
    label: '12:30'
  },
  {
    key: '12:44:59',
    label: '12:45'
  },
  {
    key: '12:59:59',
    label: '13:00'
  },
  {
    key: '13:14:59',
    label: '13:15'
  },
  {
    key: '13:29:59',
    label: '13:30'
  },
  {
    key: '13:44:59',
    label: '13:45'
  },
  {
    key: '13:59:59',
    label: '14:00'
  },
  {
    key: '14:14:59',
    label: '14:15'
  },
  {
    key: '14:29:59',
    label: '14:30'
  },
  {
    key: '14:44:59',
    label: '14:45'
  },
  {
    key: '14:59:59',
    label: '15:00'
  },
  {
    key: '15:14:59',
    label: '15:15'
  },
  {
    key: '15:29:59',
    label: '15:30'
  },
  {
    key: '15:44:59',
    label: '15:45'
  },
  {
    key: '15:59:59',
    label: '16:00'
  },
  {
    key: '16:14:59',
    label: '16:15'
  },
  {
    key: '16:29:59',
    label: '16:30'
  },
  {
    key: '16:44:59',
    label: '16:45'
  },
  {
    key: '16:59:59',
    label: '17:00'
  },
  {
    key: '17:14:59',
    label: '17:15'
  },
  {
    key: '17:29:59',
    label: '17:30'
  },
  {
    key: '17:44:59',
    label: '17:45'
  },
  {
    key: '17:59:59',
    label: '18:00'
  },
  {
    key: '18:14:59',
    label: '18:15'
  },
  {
    key: '18:29:59',
    label: '18:30'
  },
  {
    key: '18:44:59',
    label: '18:45'
  },
  {
    key: '18:59:59',
    label: '19:00'
  },
  {
    key: '19:14:59',
    label: '19:15'
  },
  {
    key: '19:29:59',
    label: '19:30'
  },
  {
    key: '19:44:59',
    label: '19:45'
  },
  {
    key: '19:59:59',
    label: '20:00'
  },
  {
    key: '20:14:59',
    label: '20:15'
  },
  {
    key: '20:29:59',
    label: '20:30'
  },
  {
    key: '20:44:59',
    label: '20:45'
  },
  {
    key: '20:59:59',
    label: '21:00'
  },
  {
    key: '21:14:59',
    label: '21:15'
  },
  {
    key: '21:29:59',
    label: '21:30'
  },
  {
    key: '21:44:59',
    label: '21:45'
  },
  {
    key: '21:59:59',
    label: '22:00'
  },
  {
    key: '22:14:59',
    label: '22:15'
  },
  {
    key: '22:29:59',
    label: '22:30'
  },
  {
    key: '22:44:59',
    label: '22:45'
  },
  {
    key: '22:59:59',
    label: '23:00'
  },
  {
    key: '23:14:59',
    label: '23:15'
  },
  {
    key: '23:29:59',
    label: '23:30'
  },
  {
    key: '23:44:59',
    label: '23:45'
  },
  {
    key: '23:59:59',
    label: '00:00'
  }
]

export const initialStartTime={
  key: '07:00:00',
  label: '07:00'
}
export const initialEndTime={
  key: '07:59:59',
  label: '08:00'
}